import React, { useEffect, useState } from "react";

import Headline from "../Article/Headline";
import Article from "../Article";
import OwnerTable from "./OwnerTable";
import { navigate } from "gatsby";
import { checkSubscribedToNFL, getGoogleSheetData } from "../../utils/helpers";
import { isAuthenticated, getUserInfo, checkUserIsAdmin } from "../../utils/auth";
import { ClipLoader } from "react-spinners";

const Ownership = props => {
  const [isSubscribedNLF, setSubscribedNLF] = useState(null);
  const [isloadingStatus, setLoadingStatus] = useState(null);
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState([]);
  const headerIds = ["id", "name", "team", "opp", "pos", "salary", "ownership"];

  const createData = (headerIds, rows) => {
    const combineRows = [];
    for (let i = 0; i < rows.length; i++) {
      let tempRow = {};
      for (let j = 0; j < headerIds.length; j++) {
        if (rows[i][j].indexOf("%") != -1) {
          tempRow[headerIds[j]] = Number(rows[i][j].replace("%", ""));
        } else {
          tempRow[headerIds[j]] = rows[i][j];
        }
      }
      combineRows.push(tempRow);
    }
    return combineRows;
  };

  const createHeaderData = (headerIds, headers) => {
    const combineHeaders = [];
    for (let i = 0; i < headerIds.length; i++) {
      let tempHead = {};
      tempHead["id"] = headerIds[i];
      tempHead["label"] = headers[i];
      if (headerIds[i] == "id" || headerIds[i] == "salary" || headerIds[i] == "ownership") {
        tempHead["numeric"] = true;
      } else {
        tempHead["numeric"] = false;
      }
      combineHeaders.push(tempHead);
    }
    return combineHeaders;
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      setSubscribedNLF(false);
    }
    setLoadingStatus(false);
    const profile = typeof window !== "undefined" ? getUserInfo() : "";

    const fetchData = async () => {
      const res = await getGoogleSheetData();
      const data = res.data;
      let batchRowValues = data.sheets[0].data[0].rowData;

      const rows = [];
      const headers = [];
      for (let h = 0; h < batchRowValues[0].values.length; h++) {
        headers.push(batchRowValues[0].values[h].formattedValue);
      }
      setHeaders(createHeaderData(headerIds, headers));
      for (let i = 1; i < batchRowValues.length; i++) {
        let rowObject = [];
        for (let j = 0; j < batchRowValues[i].values.length; j++) {
          if (batchRowValues[i].values[j].formattedValue) {
            rowObject.push(batchRowValues[i].values[j].formattedValue);
          }
        }
        if (Object.keys(rowObject).length != 0) {
          rows.push(rowObject);
        }
      }
      setRows(createData(headerIds, rows));
      setLoadingStatus(true);
    };

    const checkSubscription = async () => {
      if (checkUserIsAdmin()) {
        setSubscribedNLF(true);
        await fetchData();
      } else {
        const res = await checkSubscribedToNFL(profile.email);
        if (res === 0) {
          setSubscribedNLF(false);
        } else {
          setSubscribedNLF(true);
          await fetchData();
        }
      }
    };
    checkSubscription();
  }, []);

  if (isSubscribedNLF === null) {
    return null;
  } else if (!isSubscribedNLF) {
    navigate("/membership");
    return null;
  } else {
    let data;

    if (isloadingStatus) {
      data = <OwnerTable headersData={headers} rowsData={rows}></OwnerTable>;
    } else {
      data = (
        <div style={{ textAlign: "center" }}>
          <ClipLoader sizeUnit="px" size={100} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Article theme={props.theme}>
          <header>
            <Headline theme={props.theme}>DRAFTKINGS OWNERSHIP PROJECTIONS</Headline>
            {data}
          </header>
          <footer></footer>
        </Article>
      </React.Fragment>
    );
  }
};

export default Ownership;
