import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ThemeContext } from "../../layouts";
import { isAuthenticated, getUserInfo, ROLES_URL, checkUserIsAdmin } from "../../utils/auth";
import { navigate } from "gatsby";
import { checkSubscribedToNFL } from "../../utils/helpers";
import Ownership from "../../components/Ownership";
import Seo from "../../components/Seo";

const OwnershipPage = props => {
  const [isSubscribedNLF, setSubscribedNLF] = useState(null);

  useEffect(() => {
    if (!isAuthenticated()) {
      setSubscribedNLF(false);
    }
    const profile = typeof window !== "undefined" ? getUserInfo() : "";

    const checkSubscription = async () => {
      if (checkUserIsAdmin()) {
        setSubscribedNLF(true);
      } else {
        const res = await checkSubscribedToNFL(profile.email);
        if (res === 0) {
          setSubscribedNLF(false);
        } else {
          setSubscribedNLF(true);
        }
      }
    };
    checkSubscription();
  });

  if (isSubscribedNLF === null) {
    return null;
  } else if (!isSubscribedNLF) {
    navigate("/membership");
    return null;
  } else {
    const {
      data: {
        ranking: ranking,
        site: {
          siteMetadata: { facebook }
        }
      }
    } = props;
    console.log(props);

    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => <Ownership ranking={ranking} theme={theme} />}
        </ThemeContext.Consumer>
        <Seo facebook={facebook} />
      </React.Fragment>
    );
  }
};

OwnershipPage.propTypes = {};

export default OwnershipPage;

export const query = graphql`
  query getPrismicNflOwnership {
    ranking: prismicNflSeasonLongRankings {
      type
      slugs
      prismicId
      lang
      data {
        jon_nfl_rankings {
          embed_url
          height
          html
          title
          provider_name
          type
          version
          width
          provider_url
        }
        juntin_nfl_rankings {
          embed_url
          html
          title
          provider_name
          provider_url
          height
          width
          type
          version
        }
      }
      first_publication_date
      id
      last_publication_date
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
